import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Link from "gatsby-link"
import * as styles from "./blogStyles.module.scss"

export const query = graphql`
  query ($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        fields {
          slug
        }
        id
        frontmatter {
          title
          date(formatString: "LL")
          tags
          bucketDir
        }
      }
    }
    tagsGroup: allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
      }
    }
    allS3ImageAsset {
      nodes {
        Key
        childImageSharp {
          gatsbyImageData(
            quality: 100
            width: 800
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }
  }
`

const Blog = ({ data, pageContext }) => {
  return (
    <>
      <Layout data={data} />
      <div className={styles.nbContainer}>
        {pageContext.nextPagePath && (
          <Link to={pageContext.nextPagePath}>
            <button className={styles.nextButton}>NEXT</button>
          </Link>
        )}
      </div>
    </>
  )
}

export default Blog
